import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";
import {SectionDescription} from "components/misc/Typography.js";

import Map from "components/map/leaflet/Map"
import DecoratorBlob1 from "../blob/DecoratorBlob";
import Requisites from "./requisites/Requisites.js";

const Container = tw.div`relative`;

const TwoColumnContainer = styled.div`
    ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-2xl mx-auto py-12 md:py-16`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const HeadingSmall = tw(SectionHeading)`w-full font-bold text-3xl`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
    ${tw`md:w-full lg:w-1/2 max-w-sm`}
`;

const WideColumn = styled.div`
    ${tw`md:w-full lg:w-3/4 max-w-3xl`}
`;

const RowColumn = styled.div`
    ${tw`w-full text-center`}
`;

const TwoColumn = tw.div`flex flex-col md:flex-row md:items-center max-w-screen-xl mx-auto md:py-1`;
const LeftColumn = tw.div`relative mt-6 md:mt-0 md:w-6/12 md:pr-12 flex-shrink-0 text-left md:text-right text-primary-700 font-bold`;
const RightColumn = tw.div`relative mt-2 md:mt-0 flex flex-col text-left text-primary-500`;

const Card = styled.div`
    ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
    .imageContainer {
        ${tw`border text-center rounded-full p-5 flex-shrink-0`}
        img {
            ${tw`w-6 h-6`}
        }
    }

    .textContainer {
        ${tw`sm:ml-4 mt-4 sm:mt-2`}
    }

    .title {
        ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
    }

    .description {
        ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
    }
`;

export default ({
                  cards = null,
                  heading = "Где нас найти?",
                  subheading = "Адрес",
                  description = "Работаем в Москве и Московской области. По договорённости выезжаем и в другие города."
                }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const Address = tw.span`leading-relaxed`;
  const AddressLine = tw.span`block`;
  const Email = tw.span`text-lg mt-6 block text-gray-600`;
  const Phone = tw.span`text-lg mt-0 block text-gray-600`;

  return (
    <Container>
      <TwoColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer/>
        <Column>
          <Card>
              <span className="textContainer">
                <span className="title">Москва</span>
                <p className="description">
                  <Address>
                    <AddressLine>Мытищи, 141033</AddressLine>
                    <AddressLine>Коргашино, ул. Тарасовская, вл. 2</AddressLine>
                    <AddressLine>Павилион номер Г17</AddressLine>
                  </Address>
                  <Email>info@lestnici.pro</Email>
                  <Phone>+7 977 258-72-74</Phone>
                </p>
              </span>
          </Card>
        </Column>
        <WideColumn>
          <Map/>
        </WideColumn>

        {/*<Requisites/>*/}

      </TwoColumnContainer>
      <DecoratorBlob1/>
    </Container>
  );
};
